import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const menuItems_site = [
  { link: "/", text: "Inicio" },
  { link: "/productos", text: "Productos" },
]

const NavItems = ({ menuItems }) => {
  if (menuItems.length == 0) menuItems.push(...menuItems_site);
  return (
    <Nav className="mr-auto">
      {menuItems.map((menuItem) => (
        <Link to={menuItem.link} className="nav-link text-capitalize">
          {menuItem.text}
        </Link>
      ))}
      {/* 
          <Link to="/" className="nav-link text-capitalize">
            Inicio
          </Link>
          <Link to="/catalogo-productos" className="nav-link text-capitalize">
            Productos
          </Link>
          <Link to="/revista" className="nav-link text-capitalize">
            Revista
          </Link> */}
      {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
    </Nav>
  );
};

NavItems.defaultProps = {
  menuItems: [],
};

export default NavItems;
